.about .content ul {
    list-style: none;
    padding: 0;
}

.about .content ul li {
    padding-left: 28px;
    position: relative;
    font-size: 1.20em;
}

.about .content ul li+li {
    margin-top: 10px;
}

.about .content ul i {
    position: absolute;
    left: 0;
    top: 2px;
    font-size: 20px;
    line-height: 1;
}

.about .content p:last-child {
    margin-bottom: 0;
}

.about{
    background-color: #f4f4f4;
}
.about p{
    font-size: 1.25em;
    text-align: justify;
}