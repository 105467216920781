.jobtime{
    font-weight: bolder;
    color: #f4f4f4;
}
.jobplace{
    color: #ffc107;
}
.jobtitle{
    color: #f4f4f4;
}

.jobs {
    background-color: #4f5984;
    background-image: linear-gradient(rgba(40,58,90, 0.5), rgba(170,168,166, 0.5)),url('../img/job.jpg');
    background-size: cover;
    background-position: center center;
    overflow: hidden;
    background-attachment: fixed;
    font-family: "Montserrat", sans-serif;
    margin-bottom: 10vw;
}
.jobs p {
    color:#ffc107;
}

.jobs h2{
    color:#f4f4f4;
}

.jobs .icon-box {
    box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
    padding: 50px 30px;
    transition: all ease-in-out 0.4s;
    background: #fff;
}

.jobs .icon-box .icon {
    margin-bottom: 10px;
}

.jobs .icon-box .icon i {
    color: #47b2e4;
    font-size: 36px;
    transition: 0.3s;
}

.jobs .icon-box h4 {
    font-weight: 500;
    margin-bottom: 15px;
    font-size: 24px;
}

.jobs .icon-box h4 a {
    color: #37517e;
    transition: ease-in-out 0.3s;
}

.jobs .icon-box p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
}

.jobs .icon-box:hover {
    transform: translateY(-10px);
}

.jobs .icon-box:hover h4 a {
    color: #47b2e4;
}