#header {
    /*background-color: #0c3c53;*/
    /*  background-color: rgba(79,89,132,0.8);*/
    font-size: 1.25em;
    font-weight: normal;
    font-family: "Montserrat", sans-serif;
}

.liactive {
    color: #7090cb !important;
}

.liactive:hover {
    color: #df9424 !important;
}

.li {
    color: #df9424 !important;
}

.li:hover {
    color: #7090cb !important;
}

.navbar {
    transition: all 0.5s;
}

.active {
    background-color: #aaa8a6;
    transition: all 0.5s;
}


/*#header {*/
/*    transition: all 0.5s;*/
/*    z-index: 997;*/
/*    padding: 15px 0;*/
/*    background:#273A59;*/
/*}*/
/*#header .logo {*/
/*    font-size: 30px;*/
/*    margin: 0;*/
/*    padding: 0;*/
/*    line-height: 1;*/
/*    font-weight: 500;*/
/*    letter-spacing: 2px;*/
/*    text-transform: uppercase;*/
/*}*/
/*#header .logo img {*/
/*    max-height: 40px;*/
/*}*/
/*.navbar {*/
/*    padding: 0;*/
/*}*/

/*.navbar ul {*/
/*    margin: 0;*/
/*    padding: 0;*/
/*    display: flex;*/
/*    list-style: none;*/
/*    align-items: center;*/
/*}*/

/*.navbar li {*/
/*    position: relative;*/
/*}*/

/*.navbar a,*/
/*.navbar a:focus {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: space-between;*/
/*    padding: 10px 0 10px 30px;*/
/*    font-size: 15px;*/
/*    font-weight: 500;*/
/*    color:#606C38;*/
/*    white-space: nowrap;*/
/*    transition: 0.3s;*/
/*}*/

/*.navbar a i,*/
/*.navbar a:focus i {*/
/*    font-size: 12px;*/
/*    line-height: 0;*/
/*    margin-left: 5px;*/
/*}*/

/*.navbar a:hover,*/
/*.navbar .active,*/
/*.navbar .active:focus,*/
/*.navbar li:hover>a {*/
/*    color: #283618;*/
/*}*/

/*.navbar .getstarted,*/
/*.navbar .getstarted:focus {*/
/*    padding: 8px 20px;*/
/*    margin-left: 30px;*/
/*    border-radius: 50px;*/
/*    color: #606C38;*/
/*    font-size: 14px;*/
/*    border: 2px solid #3C1518;*/
/*    font-weight: 600;*/
/*}*/

/*.navbar .getstarted:hover,*/
/*.navbar .getstarted:focus:hover {*/
/*    color: #fff;*/
/*    background: #3C1518;*/
/*}*/

/*.navbar .dropdown ul {*/
/*    display: block;*/
/*    position: absolute;*/
/*    left: 14px;*/
/*    top: calc(100% + 30px);*/
/*    margin: 0;*/
/*    padding: 10px 0;*/
/*    z-index: 99;*/
/*    opacity: 0;*/
/*    visibility: hidden;*/
/*    background: #fff;*/
/*    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);*/
/*    transition: 0.3s;*/
/*    border-radius: 4px;*/
/*}*/

/*.navbar .dropdown ul li {*/
/*    min-width: 200px;*/
/*}*/

/*.navbar .dropdown ul a {*/
/*    padding: 10px 20px;*/
/*    font-size: 14px;*/
/*    text-transform: none;*/
/*    font-weight: 500;*/
/*    color: #0c3c53;*/
/*}*/

/*.navbar .dropdown ul a i {*/
/*    font-size: 12px;*/
/*}*/

/*.navbar .dropdown ul a:hover,*/
/*.navbar .dropdown ul .active:hover,*/
/*.navbar .dropdown ul li:hover>a {*/
/*    color: #47b2e4;*/
/*}*/

/*.navbar .dropdown:hover>ul {*/
/*    opacity: 1;*/
/*    top: 100%;*/
/*    visibility: visible;*/
/*}*/

/*.navbar .dropdown .dropdown ul {*/
/*    top: 0;*/
/*    left: calc(100% - 30px);*/
/*    visibility: hidden;*/
/*}*/

/*.navbar .dropdown .dropdown:hover>ul {*/
/*    opacity: 1;*/
/*    top: 0;*/
/*    left: 100%;*/
/*    visibility: visible;*/
/*}*/

/*@media (max-width: 1366px) {*/
/*    .navbar .dropdown .dropdown ul {*/
/*        left: -90%;*/
/*    }*/

/*    .navbar .dropdown .dropdown:hover>ul {*/
/*        left: -100%;*/
/*    }*/
/*}*/