.team .member {
    position: relative;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.4);
    padding: 30px;
    border-radius: 5px;
    background: #fff;
    transition: 0.5s;
    height: 100%;
    margin: 10px;
}

.team .member .pic {
    overflow-y: hidden;
    width: 180px;
    height: 180px;
    max-width: 100%;
    border-radius: 50%;
    background-size: cover;
    background-position: center center;
    /*background-image: url('../img/team/benoit.jpg');*/
}

.team .member .pic img {
    transition: ease-in-out 0.3s;
}

.team .member:hover {
    transform: translateY(-10px);
}

.team .member .member-info {
    padding-left: 30px;
    margin-right: 0;
    margin-left: auto;
    text-align: justify;
}

.team .member h4 {
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 20px;
    color: #37517e;
}

.team .member span {
    display: block;
    font-size: 15px;
    padding-bottom: 10px;
    position: relative;
    font-weight: 500;
}

.team .member span::after {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 2px;
    background: #cbd6e9;
    bottom: 0;
    left: 0;
}

.team .member p {
    margin: 10px 0 0 0;
    font-size: 14px;
}

.team .team-img{
    overflow: hidden;
    height: auto;
}