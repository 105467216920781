.shows .show {
    position: relative;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.4);
    padding: 30px;
    border-radius: 5px;
    background: #fff;
    transition: 0.5s;
    height: 100%;
    margin: 10px;
}

.shows .show .pic {
    overflow: hidden;
    min-width: 180px;
    border-radius: 5%;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.4);
    margin-bottom: 20px;
}

.shows .show .pic img {
    transition: ease-in-out 0.3s;
}

.shows .show:hover {
    transform: translateY(-10px);
}

.shows .show .show-info {
    padding-left: 30px;
    margin-right: 0;
    margin-left: auto;
    text-align: justify;
}

.shows .show h4 {
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 20px;
    color: #37517e;
}

.shows .show span {
    display: block;
    font-size: 15px;
    padding-bottom: 10px;
    position: relative;
    font-weight: 500;
}

.shows .show span::after {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 2px;
    background: #cbd6e9;
    bottom: 0;
    left: 0;
}

.shows .show p {
    margin: 10px 0 0 0;
    font-size: 14px;
}

.shows .shows-img{
    overflow: hidden;
    height: auto;
}
/*.team .member .social {*/
/*    margin-top: 12px;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: flex-start;*/
/*}*/

/*.team .member .social a {*/
/*    transition: ease-in-out 0.3s;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    border-radius: 50px;*/
/*    width: 32px;*/
/*    height: 32px;*/
/*    background: #eff2f8;*/
/*}*/

/*.team .member .social a i {*/
/*    color: #37517e;*/
/*    font-size: 16px;*/
/*    margin: 0 2px;*/
/*}*/

/*.team .member .social a:hover {*/
/*    background: #47b2e4;*/
/*}*/

/*.team .member .social a:hover i {*/
/*    color: #fff;*/
/*}*/

/*.team .member .social a+a {*/
/*    margin-left: 8px;*/
/*}*/