.gallery {
    display: flex;
    flex-wrap: wrap;
    margin: -1rem -1rem;
    padding-bottom: 3rem;
}

.gallery-item {
    position: relative;
    flex: 1 0 22rem;
    margin: 1rem;
    color: #fff;
    cursor: pointer;
}

.gallery-item:hover .gallery-item-info,
.gallery-item:focus .gallery-item-info {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
}

.gallery-item-info {
    display: none;
}

.gallery-item-info li {
    display: inline-block;
    font-size: 1.7rem;
    font-weight: 600;
}

.gallery-image {
    width: 100%;
    height: auto;
    object-fit: cover;
}

/*portfolio*/

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/

.portfolio{
    /*background-color: #4f5984;*/
    position: relative;
    width: 100%;
    /*height:45vh;*/
    color: #fff;
    /*min-height: 600px;*/
    padding-bottom: 30px;
    /*padding: 0;*/
    background-size: cover;
    background-position: center center;
    overflow: hidden;
    background-attachment: fixed;
    background-image: linear-gradient(rgba(40,58,90, 0.3), rgba(170,168,166, 0.3)),url('../img/gallery.jpg');
}
.portfolio h2{
    color: #f4f4f4;
}
.portfolio p {
    color:#ffc107;
}

.portfolio .portfolio-item {
    margin-bottom: 30px;
}

.portfolio .portfolio-item .portfolio-img {
    overflow: hidden;
}

.portfolio .portfolio-item .portfolio-img img {
    transition: all 0.6s;
}
.portfolio .portfolio-img{
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.4);
}
.portfolio .portfolio-item .portfolio-info {
    opacity: 0;
    position: absolute;
    left: 15px;
    bottom: 0;
    z-index: 3;
    right: 15px;
    transition: all 0.3s;
    background: rgba(55, 81, 126, 0.8);
    padding: 10px 15px;
}

.portfolio .portfolio-item .portfolio-info h4 {
    font-size: 18px;
    color: #fff;
    font-weight: 600;
    color: #fff;
    margin-bottom: 0px;
}

.portfolio-container{
    width: 70%;
}

.portfolio .portfolio-item .portfolio-info p {
    color: #f9fcfe;
    font-size: 14px;
    margin-bottom: 0;
}

.portfolio .portfolio-item .portfolio-info .preview-link,
.portfolio .portfolio-item .portfolio-info .details-link {
    position: absolute;
    right: 40px;
    font-size: 24px;
    top: calc(50% - 18px);
    color: #fff;
    transition: 0.3s;
}

.portfolio .portfolio-item .portfolio-info .preview-link:hover,
.portfolio .portfolio-item .portfolio-info .details-link:hover {
    color: #47b2e4;
}

.portfolio .portfolio-item .portfolio-info .details-link {
    right: 10px;
}

.portfolio .portfolio-item:hover .portfolio-img img {
    transform: scale(1.15);
}

.portfolio .portfolio-item:hover .portfolio-info {
    opacity: 1;
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
    padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
    width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
    margin-top: 20px;
    position: relative;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #47b2e4;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #47b2e4;
}

.portfolio-details .portfolio-info {
    padding: 30px;
    box-shadow: 0px 0 30px rgba(55, 81, 126, 0.08);
}

.portfolio-details .portfolio-info h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
}

.portfolio-details .portfolio-info ul li+li {
    margin-top: 10px;
}

.portfolio-details .portfolio-description {
    padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
    padding: 0;
}

/*.overlaylay {*/
/*    position: absolute;*/
/*    top: 0;*/
/*    bottom: 0;*/
/*    left: 0;*/
/*    right: 0;*/
/*    height: 100%;*/
/*    width: 100%;*/
/*    opacity: 0;*/
/*    transition: .5s ease;*/
/*    background-color: #7090cb;*/
/*}*/

/*.containeroverlay:hover .overlaylay {*/
/*    opacity: 1;*/
/*}*/
/*.containeroverlay {*/
/*    position: relative;*/
/*}*/
/*.imageoverlay {*/
/*    display: block;*/
/*    width: 100%;*/
/*    height: auto;*/
/*}*/
/*.text {*/
/*    color: white;*/
/*    font-size: 20px;*/
/*    position: absolute;*/
/*    top: 50%;*/
/*    left: 50%;*/
/*    -webkit-transform: translate(-50%, -50%);*/
/*    -ms-transform: translate(-50%, -50%);*/
/*    transform: translate(-50%, -50%);*/
/*    text-align: center;*/
/*}*/
/*.text span{*/
/*    color: #aaa8a6;*/
/*}*/