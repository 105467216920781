
/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info {
    padding: 30px;
    background: #fff;
    width: 100%;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.4);
}

.contact .info i {
    font-size: 20px;
    color: #47b2e4;
    float: left;
    width: 44px;
    height: 44px;
    background: #e7f5fb;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
}

.contact .info .fb,
.contact .info .insta,
.contact .info .mailto{
    color: #47b2e4;
    float: left;
    width: 44px;
    height: 44px;
    background: #e7f5fb;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
    padding: 10px;
    margin: 10px;
}
.submitbtn{
    background-color: #4f5984!important;
    margin: 10px;
}

.submitbtn :hover{
    background-color: #ffc107!important;
}

.contact .info h4 {
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #37517e;
}

.contact .info p {
    padding: 0 0 10px 60px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #6182ba;
}

.contact .info .email p {
    padding-top: 5px;
}

.contact .info .social-links {
    padding-left: 60px;
}

.contact .info .fb:hover i,
.contact .info .insta:hover i,
.contact .info .mailto:hover i,
.contact .info .email:hover i,
.contact .info .address:hover i,
.contact .info .phone:hover i {
    background: #47b2e4;
    color: #fff;
    padding: 10px;
}
