.hero {
    position: relative;
    min-width: 100%;
    /*height:100vh;*/
    color: #df9424;

    min-height: 100vh;
    padding: 0;
    padding-bottom: 50px;
    background-size: cover;
    background-position: center center;
    /*overflow: hidden;*/
    background-attachment: fixed;
    /*background-image: linear-gradient(rgba(40,58,90, 0.5), rgba(40,58,90, 0.5)),url('../../../assets/img/hero1.jpg');*/
}

.hero-content {
    position: relative;
    width: 100%;
    /* top: 50%; */
    /*-webkit-transform: translate(0, -50%);*/
    /*transform: translate(0, -50%);*/
    /*-webkit-transition: all 0.3s;*/
    /*transition: all 0.3s;*/
    z-index: 10;
}


/*#hero {*/
/*    width: 100%;*/
/*    height: 80vh;*/
/*    background: #273A59;*/
/*}*/
.hero a {
    text-decoration: none;
}

.hero .container {
    padding-top: 72px;
}

/*#hero-content{*/
/*    -webkit-transform: translate(0, -50%);*/
/*}*/
.hero h1 {
    margin: 0 0 10px 0;
    font-weight: lighter;
    line-height: 56px;
}

.hero h2 {
    margin-bottom: 50px;
}

.hero .btn-signin {
    font-family: "Jost", sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 28px 11px 28px;
    border-radius: 50px;
    transition: 0.5s;
    margin: 10px 10px 0 0;
    color: #fff;
    background: #47b2e4;
}

.hero .btn-signin:hover {
    background: #209dd8;
}

.hero .btn-recrut {
    font-family: "Jost", sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 28px 11px 28px;
    border-radius: 50px;
    transition: 0.5s;
    margin: 10px 10px 0 0;
    color: #47b2e4;
    background: #ffffff;
}

.hero .btn-recrut:hover {
    background: #dddddd;
}