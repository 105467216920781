#cta {
    position: relative;
    width: 100%;
    height:45vh;
    color: #fff;
    /*min-height: 600px;*/
    padding: 0;
    background-size: cover;
    background-position: center center;
    overflow: hidden;
    background-attachment: fixed;
    background-image: linear-gradient(rgba(40,58,90, 0.5), rgba(170,168,166, 0.5)),url('../img/cta.jpg');
}
#cta-content {
    position: relative;
    width: 100%;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    z-index: 10;
}
#cta p{
    color:#aaa8a6;
}