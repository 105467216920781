footer{
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #7090cb;
    font-size: 15px;
    font-weight: bolder;
    color: #f4f4f4;
}
footer a {
    color: #aaa8a6;
    text-decoration: none;
}